

<script>
import Layout from "../../layouts/main.vue";
import PageHeader from "@/components/page-header";
import { useToast } from "vue-toastification";
import {buyProduct} from "@/helpers/donate";

export default {
  name: "index",
  components: {
    Layout,
    PageHeader,
  },
  data() {
    return {
      toast: useToast(),
      title: "Донат",
      items: [
        {
          text: "Аккаунт",
          href: "/",
        },
        {
          text: "Мой профиль",
          href: "/",
        },
        {
          text: "Донат",
          active: true,
        },
      ],
      product: {
        key: '',
        count: 1,
        price: 1,
        name: ''
      },
      counts: {
        flag: 1,
        shulker_box: 1,
        key: 1,
        key_money: 1,
        key_money_big: 1,
        key_tools: 1,
        tnt: 1
      }
    }
  },
  methods: {
    dicrimCount(key) {
      if (this.counts[key] - 1 < 1) {
        return;
      }

      this.counts[key] = this.counts[key] - 1;
    },
    buy(key, name, count, price) {
      this.product = {
        key, count, name, price
      }
    },
    async sendBuy() {
        await buyProduct(this.product.key, this.product.count);
    }
  }
}
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />

    <div class="row justify-content-center mt-5">
      <div class="col-lg-5">
        <div class="text-center mb-4 pb-2">
          <h4 class="fw-semibold fs-22">
            Приобретая донат
          </h4>
          <p class="text-muted mb-4 fs-15">
            Ваша игра на проекте будет ещё более интересной
          </p>
        </div>
      </div>
      <!--end col-->
    </div>
    <!--end row-->

    <div class="row justify-content-center">
      <div class="col-xl-9">
        <div class="row">
          <div class="col-lg-4">
            <div class="card pricing-box ribbon-box right">
              <div class="card-body p-4 m-2">
                <div class="ribbon-two ribbon-two-danger">
                  <span>-15%</span>
                </div>
                <div class="d-flex align-items-center">
                  <div class="flex-grow-1">
                    <h5 class="mb-1 fw-semibold">VIP</h5>
                    <p class="text-muted mb-0">Неплохое начало</p>
                  </div>
                  <div class="avatar-sm">
                    <div
                        class="avatar-title bg-light rounded-circle text-primary"
                    >
                      <i class="ri-book-mark-line fs-20"></i>
                    </div>
                  </div>
                </div>
                <div class="pt-4">
                  <h2>
                    <sup><small>₽</small></sup>
                    215
                    <span class="fs-13 text-muted">/ Месяц</span>
                  </h2>
                </div>
                <hr class="my-4 text-muted" />
                <div>
                  <ul class="list-unstyled text-muted vstack gap-3">
                    <li>
                      <div class="d-flex">
                        <div class="flex-shrink-0 text-success me-1">
                          <i
                              class="ri-checkbox-circle-fill fs-15 align-middle"
                          ></i>
                        </div>
                        <div class="flex-grow-1">
                          Возможность входа на заполненный игровой сервер
                        </div>
                      </div>
                    </li>
                    <li>
                      <div class="d-flex">
                        <div class="flex-shrink-0 text-success me-1">
                          <i
                              class="ri-checkbox-circle-fill fs-15 align-middle"
                          ></i>
                        </div>
                        <div class="flex-grow-1">
                          Открытие верстака в любой точке мира командой
                          <b>/craft (/workbench)</b>
                        </div>
                      </div>
                    </li>
                    <li>
                      <div class="d-flex">
                        <div class="flex-shrink-0 text-success me-1">
                          <i
                              class="ri-checkbox-circle-fill fs-15 align-middle"
                          ></i>
                        </div>
                        <div class="flex-grow-1">
                          Открытие эндер-сундука в любой точке мира командой <b>/ec (/enderchest)</b>
                        </div>
                      </div>
                    </li>
                    <li>
                      <div class="d-flex">
                        <div class="flex-shrink-0 text-success me-1">
                          <i
                              class="ri-checkbox-circle-fill fs-15 align-middle"
                          ></i>
                        </div>
                        <div class="flex-grow-1">
                          Доступ к стилям структур: <b>корейский стиль, индийский стиль</b>
                        </div>
                      </div>
                    </li>
                    <li>
                      <div class="d-flex">
                        <div class="flex-shrink-0 text-success me-1">
                          <i
                              class="ri-checkbox-circle-fill fs-15 align-middle"
                          ></i>
                        </div>
                        <div class="flex-grow-1">
                          Наборы предметов: <b>VIP, Food, Itools</b>
                        </div>
                      </div>
                    </li>
                    <li>
                      <div class="d-flex">
                        <div class="flex-shrink-0 text-success me-1">
                          <i
                              class="ri-checkbox-circle-fill fs-15 align-middle"
                          ></i>
                        </div>
                        <div class="flex-grow-1">
                          Цвет логина и префикса: <b>жёлтый</b>
                        </div>
                      </div>
                    </li>
                  </ul>
                  <div class="mt-4">
                    <a
                        data-bs-toggle="modal"
                        data-bs-target=".accept-buy"
                        @click="buy('vip', 'VIP', 1, 215)"
                        class="btn btn-soft-success waves-effect waves-light w-100"
                    >
                      Приобрести
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!--end col-->
          <div class="col-lg-4">
            <div class="card pricing-box ribbon-box right">
              <div class="card-body p-4 m-2">
                <div class="ribbon-two ribbon-two-danger">
                  <span>-15%</span>
                </div>
              <div>
                  <div class="d-flex align-items-center">
                    <div class="flex-grow-1">
                      <h5 class="mb-1 fw-semibold">Deluxe</h5>
                      <p class="text-muted mb-0">Лучший вариант</p>
                    </div>
                    <div class="avatar-sm">
                      <div
                          class="
                          avatar-title
                          bg-light
                          rounded-circle
                          text-primary
                        "
                      >
                        <i class="ri-medal-line fs-20"></i>
                      </div>
                    </div>
                  </div>

                  <div class="pt-4">
                    <h2>
                      <sup><small>₽</small></sup>
                      585
                      <span class="fs-13 text-muted">
                        / Месяц
                      </span
                    >
                    </h2>
                  </div>
                </div>
                <hr class="my-4 text-muted" />
                <div>
                  <ul class="list-unstyled vstack gap-3 text-muted">
                    <li>
                      <div class="d-flex">
                        <div class="flex-shrink-0 text-success me-1">
                          <i
                              class="ri-checkbox-circle-fill fs-15 align-middle"
                          ></i>
                        </div>
                        <div class="flex-grow-1">
                          Все возможности донат-статусов <b>VIP</b> и <b>Premium</b>
                        </div>
                      </div>
                    </li>
                    <li>
                      <div class="d-flex">
                        <div class="flex-shrink-0 text-success me-1">
                          <i
                              class="ri-checkbox-circle-fill fs-15 align-middle"
                          ></i>
                        </div>
                        <div class="flex-grow-1">
                          Не кикает с сервера за <b>AFK более 10 минут</b>
                        </div>
                      </div>
                    </li>
                    <li>
                      <div class="d-flex">
                        <div class="flex-shrink-0 text-success me-1">
                          <i
                              class="ri-checkbox-circle-fill fs-15 align-middle"
                          ></i>
                        </div>
                        <div class="flex-grow-1">
                          Доступ к стилям структур: <b>итальянский стиль, испанский стиль, кубический стиль</b>
                        </div>
                      </div>
                    </li>
                    <li>
                      <div class="d-flex">
                        <div class="flex-shrink-0 text-success me-1">
                          <i
                              class="ri-checkbox-circle-fill fs-15 align-middle"
                          ></i>
                        </div>
                        <div class="flex-grow-1">
                          Наборы предметов: <b>Deluxe, Civilization, War</b>
                        </div>
                      </div>
                    </li>
                    <li>
                      <div class="d-flex">
                        <div class="flex-shrink-0 text-success me-1">
                          <i
                              class="ri-checkbox-circle-fill fs-15 align-middle"
                          ></i>
                        </div>
                        <div class="flex-grow-1">
                          Цвет ника: <b>цвет морской волны</b>
                        </div>
                      </div>
                    </li>
                    <li>
                      <div class="d-flex">
                        <div class="flex-shrink-0 text-success me-1">
                          <i
                              class="ri-checkbox-circle-fill fs-15 align-middle"
                          ></i>
                        </div>
                        <div class="flex-grow-1">
                          Цвет префикса: <b>цвет оттенков моря</b>
                        </div>
                      </div>
                    </li>
                  </ul>
                  <div class="mt-4">
                    <a
                        data-bs-toggle="modal"
                        data-bs-target=".accept-buy"
                        @click="buy('deluxe', 'Deluxe', 1, 585)"
                        class="btn btn-soft-success waves-effect waves-light w-100"
                    >Приобрести</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!--end col-->
          <div class="col-lg-4">
            <div class="card pricing-box ribbon-box right">
              <div class="card-body p-4 m-2">
                <div class="ribbon-two ribbon-two-danger">
                  <span>-15%</span>
                </div>
                <div>
                  <div class="d-flex align-items-center">
                    <div class="flex-grow-1">
                      <h5 class="mb-1 fw-semibold">Premium</h5>
                      <p class="text-muted mb-0">Популярный выбор</p>
                    </div>
                    <div class="avatar-sm">
                      <div
                          class="
                          avatar-title
                          bg-light
                          rounded-circle
                          text-primary
                        "
                      >
                        <i class="ri-stack-line fs-20"></i>
                      </div>
                    </div>
                  </div>

                  <div class="pt-4">
                    <h2>
                      <sup><small>₽</small></sup>
                      475
                      <span class="fs-13 text-muted">
                        / Месяц
                      </span>
                    </h2>
                  </div>
                </div>
                <hr class="my-4 text-muted" />
                <div>
                  <ul class="list-unstyled vstack gap-3 text-muted">
                    <li>
                      <div class="d-flex">
                        <div class="flex-shrink-0 text-success me-1">
                          <i
                              class="ri-checkbox-circle-fill fs-15 align-middle"
                          ></i>
                        </div>
                        <div class="flex-grow-1">
                          Все возможности донат-статуса <b>VIP</b>
                        </div>
                      </div>
                    </li>
                    <li>
                      <div class="d-flex">
                        <div class="flex-shrink-0 text-success me-1">
                          <i
                              class="ri-checkbox-circle-fill fs-15 align-middle"
                          ></i>
                        </div>
                        <div class="flex-grow-1">
                          Доступ в библиотеку, возведённую на спавне и не доступную обычным игрокам <b>(/warp library)</b>
                        </div>
                      </div>
                    </li>
                    <li>
                      <div class="d-flex">
                        <div class="flex-shrink-0 text-success me-1">
                          <i
                              class="ri-checkbox-circle-fill fs-15 align-middle"
                          ></i>
                        </div>
                        <div class="flex-grow-1">
                          Возможность установить время суток, отображаемое только для Вас, командой <b>/ptime</b>
                        </div>
                      </div>
                    </li>
                    <li>
                      <div class="d-flex">
                        <div class="flex-shrink-0 text-success me-1">
                          <i
                              class="ri-checkbox-circle-fill fs-15 align-middle"
                          ></i>
                        </div>
                        <div class="flex-grow-1">
                          Возможность установить погодные условия, отображаемые только для Вас, командой <b>/pweather</b>
                        </div>
                      </div>
                    </li>
                    <li>
                      <div class="d-flex">
                        <div class="flex-shrink-0 text-success me-1">
                          <i
                              class="ri-checkbox-circle-fill fs-15 align-middle"
                          ></i>
                        </div>
                        <div class="flex-grow-1">
                          Доступ к стилям структур: <b>японский стиль, монгольский стиль, мечтательный стиль</b>
                        </div>
                      </div>
                    </li>
                    <li>
                      <div class="d-flex">
                        <div class="flex-shrink-0 text-success me-1">
                          <i
                              class="ri-checkbox-circle-fill fs-15 align-middle"
                          ></i>
                        </div>
                        <div class="flex-grow-1">
                          Наборы предметов: <b>Premium, Camp, Dtools</b>
                        </div>
                      </div>
                    </li>
                    <li>
                      <div class="d-flex">
                        <div class="flex-shrink-0 text-success me-1">
                          <i class="ri-checkbox-circle-fill fs-15 align-middle"></i>
                        </div>
                        <div class="flex-grow-1">
                          Цвет логина и префикса: <b>розовый</b>
                        </div>
                      </div>
                    </li>
                  </ul>
                  <div class="mt-4">
                    <a
                        data-bs-toggle="modal"
                        data-bs-target=".accept-buy"
                        @click="buy('premium', 'Premium', 1, 475)"
                        class="btn btn-soft-success waves-effect waves-light w-100"
                    >
                      Приобрести
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!--end col-->
        </div>
        <!--end row-->
      </div>
      <!--end col-->
    </div>
    <!--end row-->

    <div class="row justify-content-center mt-5">
      <div class="col-lg-5">
        <div class="text-center mb-4 pb-2">
          <h4 class="fw-semibold fs-22">
            Полезные предметы
          </h4>
          <p class="text-muted mb-4 fs-15">
            Для тех, кто точно знает, что хочет
          </p>
        </div>
      </div>
      <!--end col-->
    </div>
    <!--end row-->

    <div class="row d-flex align-items-center justify-content-center gap-2">
      <div class="card product m-0" style="max-width: 800px">
        <div class="card-body">
          <div class="row gy-3">
            <div class="col-sm-auto">
              <div class="avatar-lg bg-light rounded p-1">
                <img
                    src="@/assets/images/items/ender_chest.png"
                    alt=""
                    class="img-fluid d-block"
                />
              </div>
            </div>
            <div class="col-sm">
              <h5 class="fs-14 text-truncate">
                <span class="text-dark">
                  Доступ к эндер-сундуку и верстаку
                </span>
              </h5>
              <ul class="list-inline text-muted">
                Предоставляется на <b>30</b> суток<br>
                Использование команды: <b>/enderchest (/ender или /ec)</b><br>
                Использование команды: <b>/workbench (/wb или /craft)</b>
              </ul>
            </div>
            <div class="col-sm-auto">
              <div class="text-lg-end">
                <p class="text-muted mb-1">Стоимость предмета:</p>
                <h5 class="fs-14">
                  ₽<span id="ticket_price" class="product-price">75</span>
                </h5>
                <button
                    data-bs-toggle="modal"
                    data-bs-target=".accept-buy"
                    @click="buy('ender', 'Доступ к эндер-сундуку и верстаку', 1, 75)"
                    class="btn btn-soft-success waves-effect waves-light"
                >Приобрести</button>
              </div>
            </div>
          </div>
        </div>
        <!-- card body -->
      </div>
      <!-- end card -->

      <div class="card product m-0" style="max-width: 800px">
        <div class="card-body">
          <div class="row gy-3">
            <div class="col-sm-auto">
              <div class="avatar-lg bg-light rounded p-1">
                <img
                    src="@/assets/images/items/flag.png"
                    alt=""
                    class="img-fluid d-block"
                />
              </div>
            </div>
            <div class="col-sm">
              <h5 class="fs-14 text-truncate">
                <span class="text-dark">
                  Флаг основания цивилизации
                </span>
              </h5>
              <ul class="list-inline text-muted">
                Позволяет основать цивилизацию
              </ul>

              <div class="input-step">
                <button @click="dicrimCount('flag')" type="button" class="minus">–</button>
                <input
                    type="number"
                    disabled
                    class="product-quantity"
                    v-model="counts.flag"
                    min="0"
                    max="100"
                />
                <button @click="counts.flag++" type="button" class="plus">+</button>
              </div>
            </div>
            <div class="col-sm-auto">
              <div class="text-lg-end">
                <p class="text-muted mb-1">Стоимость предмета:</p>
                <h5 class="fs-14">
                  ₽<span id="ticket_price" class="product-price">250</span>
                </h5>
                <button
                    data-bs-toggle="modal"
                    data-bs-target=".accept-buy"
                    @click="buy('flag', 'Флаг основания цивилизации', counts.flag, 250)"
                    class="btn btn-soft-success waves-effect waves-light"
                >Приобрести</button>
              </div>
            </div>
          </div>
        </div>
        <!-- card body -->
      </div>
      <!-- end card -->

      <div class="card product m-0" style="max-width: 800px">
        <div class="card-body">
          <div class="row gy-3">
            <div class="col-sm-auto">
              <div class="avatar-lg bg-light rounded p-1">
                <img
                    src="@/assets/images/items/tnt.png"
                    alt=""
                    class="img-fluid d-block"
                />
              </div>
            </div>
            <div class="col-sm">
              <h5 class="fs-14 text-truncate">
                <span class="text-dark">
                  Динамит
                </span>
              </h5>
              <ul class="list-inline text-muted">
                Блок, позволяющий взрывать местность вокруг него
              </ul>

              <div class="input-step">
                <button @click="dicrimCount('tnt')" type="button" class="minus">–</button>
                <input
                    type="number"
                    disabled
                    class="product-quantity"
                    v-model="counts.tnt"
                    min="0"
                    max="100"
                />
                <button @click="counts.tnt++" type="button" class="plus">+</button>
              </div>
            </div>
            <div class="col-sm-auto">
              <div class="text-lg-end">
                <p class="text-muted mb-1">Стоимость предмета:</p>
                <h5 class="fs-14">
                  ₽<span id="ticket_price" class="product-price">2</span>
                </h5>
                <button
                    data-bs-toggle="modal"
                    data-bs-target=".accept-buy"
                    @click="buy('tnt', 'Динамит', counts.tnt, 2)"
                    class="btn btn-soft-success waves-effect waves-light"
                >Приобрести</button>
              </div>
            </div>
          </div>
        </div>
        <!-- card body -->
      </div>
      <!-- end card -->

      <div class="card product m-0" style="max-width: 800px">
        <div class="card-body">
          <div class="row gy-3">
            <div class="col-sm-auto">
              <div class="avatar-lg bg-light rounded p-1">
                <img
                    src="@/assets/images/items/shulker_box.png"
                    alt=""
                    class="img-fluid d-block"
                />
              </div>
            </div>
            <div class="col-sm">
              <h5 class="fs-14 text-truncate">
                <span class="text-dark">
                  Шалкеровый ящик
                </span>
              </h5>
              <ul class="list-inline text-muted">
                Блок на подобии сундука, в котором можно хранить предметы
              </ul>

              <div class="input-step">
                <button @click="dicrimCount('shulker_box')" type="button" class="minus">–</button>
                <input
                    type="number"
                    disabled
                    class="product-quantity"
                    v-model="counts.shulker_box"
                    min="0"
                    max="100"
                />
                <button @click="counts.shulker_box++" type="button" class="plus">+</button>
              </div>
            </div>
            <div class="col-sm-auto">
              <div class="text-lg-end">
                <p class="text-muted mb-1">Стоимость предмета:</p>
                <h5 class="fs-14">
                  ₽<span id="ticket_price" class="product-price">50</span>
                </h5>
                <button
                    data-bs-toggle="modal"
                    data-bs-target=".accept-buy"
                    @click="buy('shulker_box', 'Шалкеровый ящик', counts.shulker_box, 50)"
                    class="btn btn-soft-success waves-effect waves-light"
                >Приобрести</button>
              </div>
            </div>
          </div>
        </div>
        <!-- card body -->
      </div>
      <!-- end card -->
    </div>

    <div class="row justify-content-center mt-5">
      <div class="col-lg-5">
        <div class="text-center mb-4 pb-2">
          <h4 class="fw-semibold fs-22">
            Ключи
          </h4>
          <p class="text-muted mb-4 fs-15">
            Для тех, кто любит рисковать
          </p>
        </div>
      </div>
      <!--end col-->
    </div>
    <!--end row-->

    <div class="row d-flex align-items-center justify-content-center gap-2 mb-5">
      <div class="card product m-0" style="max-width: 800px">
        <div class="card-body">
          <div class="row gy-3">
            <div class="col-sm-auto">
              <div class="avatar-lg bg-light rounded p-1">
                <img
                    src="@/assets/images/items/key-1.png"
                    alt=""
                    class="img-fluid d-block"
                />
              </div>
            </div>
            <div class="col-sm">
              <h5 class="fs-14 text-truncate">
                <span class="text-dark">
                  Ключ от кейса с инструментами
                </span>
              </h5>
              <ul class="list-inline text-muted">
                Содержит в себе различные инструменты
              </ul>
              <div class="input-step">
                <button @click="dicrimCount('key_tools')" type="button" class="minus">–</button>
                <input
                    type="number"
                    disabled
                    class="product-quantity"
                    v-model="counts.key_tools"
                    min="0"
                    max="100"
                />
                <button @click="counts.key_tools++" type="button" class="plus">+</button>
              </div>
            </div>
            <div class="col-sm-auto">
              <div class="text-lg-end">
                <p class="text-muted mb-1">Стоимость предмета:</p>
                <h5 class="fs-14">
                  ₽<span id="ticket_price" class="product-price">150</span>
                </h5>
                <button
                    data-bs-toggle="modal"
                    data-bs-target=".accept-buy"
                    @click="buy('key_tools', 'Ключ от кейса с инструментами', counts.key_tools, 150)"
                    class="btn btn-soft-success waves-effect waves-light"
                >Приобрести</button>
              </div>
            </div>
          </div>
        </div>
        <!-- card body -->
      </div>
      <!-- end card -->

      <div class="card product m-0" style="max-width: 800px">
        <div class="card-body">
          <div class="row gy-3">
            <div class="col-sm-auto">
              <div class="avatar-lg bg-light rounded p-1">
                <img
                    src="@/assets/images/items/key-2.png"
                    alt=""
                    class="img-fluid d-block"
                />
              </div>
            </div>
            <div class="col-sm">
              <h5 class="fs-14 text-truncate">
                <span class="text-dark">
                  Ключ от особого кейса
                </span>
              </h5>
              <ul class="list-inline text-muted">
                Содержит в себе полезные предметы
              </ul>
              <div class="input-step">
                <button @click="dicrimCount('key')" type="button" class="minus">–</button>
                <input
                    type="number"
                    disabled
                    class="product-quantity"
                    v-model="counts.key"
                    min="0"
                    max="100"
                />
                <button @click="counts.key++" type="button" class="plus">+</button>
              </div>
            </div>
            <div class="col-sm-auto">
              <div class="text-lg-end">
                <p class="text-muted mb-1">Стоимость предмета:</p>
                <h5 class="fs-14">
                  ₽<span id="ticket_price" class="product-price">150</span>
                </h5>
                <button
                    data-bs-toggle="modal"
                    data-bs-target=".accept-buy"
                    @click="buy('key', 'Ключ от особого кейса', counts.key, 150)"
                    class="btn btn-soft-success waves-effect waves-light"
                >Приобрести</button>
              </div>
            </div>
          </div>
        </div>
        <!-- card body -->
      </div>
      <!-- end card -->

      <div class="card product m-0" style="max-width: 800px">
        <div class="card-body">
          <div class="row gy-3">
            <div class="col-sm-auto">
              <div class="avatar-lg bg-light rounded p-1">
                <img
                    src="@/assets/images/items/key-3.png"
                    alt=""
                    class="img-fluid d-block"
                />
              </div>
            </div>
            <div class="col-sm">
              <h5 class="fs-14 text-truncate">
                <span class="text-dark">
                  Ключ от кейса доходов
                </span>
              </h5>
              <ul class="list-inline text-muted">
                Содержит в себе различные суммы
              </ul>
              <div class="input-step">
                <button @click="dicrimCount('key_money')" type="button" class="minus">–</button>
                <input
                    type="number"
                    disabled
                    class="product-quantity"
                    v-model="counts.key_money"
                    min="0"
                    max="100"
                />
                <button @click="counts.key_money++" type="button" class="plus">+</button>
              </div>
            </div>
            <div class="col-sm-auto">
              <div class="text-lg-end">
                <p class="text-muted mb-1">Стоимость предмета:</p>
                <h5 class="fs-14">
                  ₽<span id="ticket_price" class="product-price">100</span>
                </h5>
                <button
                    data-bs-toggle="modal"
                    data-bs-target=".accept-buy"
                    @click="buy('key_money', 'Ключ от кейса доходов', counts.key_money, 100)"
                    class="btn btn-soft-success waves-effect waves-light"
                >Приобрести</button>
              </div>
            </div>
          </div>
        </div>
        <!-- card body -->
      </div>
      <!-- end card -->

      <div class="card product m-0" style="max-width: 800px">
        <div class="card-body">
          <div class="row gy-3">
            <div class="col-sm-auto">
              <div class="avatar-lg bg-light rounded p-1">
                <img
                    src="@/assets/images/items/key-3.png"
                    alt=""
                    class="img-fluid d-block"
                />
              </div>
            </div>
            <div class="col-sm">
              <h5 class="fs-14 text-truncate">
                <span class="text-dark">
                  Ключ от кейса сверхдоходов
                </span>
              </h5>
              <ul class="list-inline text-muted">
                Содержит в себе различные суммы
              </ul>
              <div class="input-step">
                <button @click="dicrimCount('key_money_big')" type="button" class="minus">–</button>
                <input
                    type="number"
                    disabled
                    class="product-quantity"
                    v-model="counts.key_money_big"
                    min="0"
                    max="100"
                />
                <button @click="counts.key_money_big++" type="button" class="plus">+</button>
              </div>
            </div>
            <div class="col-sm-auto">
              <div class="text-lg-end">
                <p class="text-muted mb-1">Стоимость предмета:</p>
                <h5 class="fs-14">
                  ₽<span id="ticket_price" class="product-price">300</span>
                </h5>
                <button
                    data-bs-toggle="modal"
                    data-bs-target=".accept-buy"
                    @click="buy('key_money_big', 'Ключ от кейса сверхдоходов', counts.key_money_big, 300)"
                    class="btn btn-soft-success waves-effect waves-light"
                >Приобрести</button>
              </div>
            </div>
          </div>
        </div>
        <!-- card body -->
      </div>
      <!-- end card -->
    </div>

    <div class="row justify-content-center mt-5">
      <div class="col-lg-5">
        <div class="text-center mb-4 pb-2">
          <h4 class="fw-semibold fs-22">
            Персонализация
          </h4>
          <p class="text-muted mb-4 fs-15">
            Для тех, кто хочет выглядить стильно
          </p>
        </div>
      </div>
      <!--end col-->
    </div>
    <!--end row-->

    <div class="row d-flex align-items-center justify-content-center gap-2 mb-5">
      <div class="card product m-0" style="max-width: 800px">
        <div class="card-body">
          <div class="row gy-3">
            <div class="col-sm-auto">
              <div class="avatar-lg bg-light rounded p-1">
                <img
                    src="@/assets/images/items/cloack.png"
                    alt=""
                    class="img-fluid d-block"
                />
              </div>
            </div>
            <div class="col-sm">
              <h5 class="fs-14 text-truncate">
                <span class="text-dark">
                  Плащ
                </span>
              </h5>
              <ul class="list-inline text-muted">
                Позволяет установить плащ любых размеров<br>
                <b>Распространяется на все сервера проекта</b>
              </ul>
            </div>
            <div class="col-sm-auto">
              <div class="text-lg-end">
                <p class="text-muted mb-1">Стоимость предмета:</p>
                <h5 class="fs-14">
                  ₽<span id="ticket_price" class="product-price">100</span>
                </h5>
                <button
                    data-bs-toggle="modal"
                    data-bs-target=".accept-buy"
                    @click="buy('clock', 'Плащ', 1, 100)"
                    class="btn btn-soft-success waves-effect waves-light"
                >Приобрести</button>
              </div>
            </div>
          </div>
        </div>
        <!-- card body -->
      </div>
      <!-- end card -->

      <div class="card product m-0" style="max-width: 800px">
        <div class="card-body">
          <div class="row gy-3">
            <div class="col-sm-auto">
              <div class="avatar-lg bg-light rounded p-1">
                <img
                    src="@/assets/images/items/hd_skin.png"
                    alt=""
                    class="img-fluid d-block"
                />
              </div>
            </div>
            <div class="col-sm">
              <h5 class="fs-14 text-truncate">
                <span class="text-dark">
                  HD скин
                </span>
              </h5>
              <ul class="list-inline text-muted">
                Позволяет установить скин любых размеров<br>
                <b>Распространяется на все сервера проекта</b>
              </ul>
            </div>
            <div class="col-sm-auto">
              <div class="text-lg-end">
                <p class="text-muted mb-1">Стоимость предмета:</p>
                <h5 class="fs-14">
                  ₽<span id="ticket_price" class="product-price">100</span>
                </h5>
                <button
                    data-bs-toggle="modal"
                    data-bs-target=".accept-buy"
                    @click="buy('skin_hd', 'HD скин', 1, 100)"
                    class="btn btn-soft-success waves-effect waves-light"
                >
                  Приобрести
                </button>
              </div>
            </div>
          </div>
        </div>
        <!-- card body -->
      </div>
      <!-- end card -->

      <div class="card product m-0" style="max-width: 800px">
        <div class="card-body">
          <div class="row gy-3">
            <div class="col-sm-auto">
              <div class="avatar-lg bg-light rounded p-1">
                <img
                    src="@/assets/images/items/avatar.png"
                    alt=""
                    class="img-fluid d-block"
                />
              </div>
            </div>
            <div class="col-sm">
              <h5 class="fs-14 text-truncate">
                <span class="text-dark">
                  Дополнительная персонализация
                </span>
              </h5>
              <ul class="list-inline text-muted">
                Позволяет установить аватар и банер на сайт<br>
                <b>Распространяется на все сервера проекта</b>
              </ul>
            </div>
            <div class="col-sm-auto">
              <div class="text-lg-end">
                <p class="text-muted mb-1">Стоимость предмета:</p>
                <h5 class="fs-14">
                  ₽<span id="ticket_price" class="product-price">100</span>
                </h5>
                <button
                    data-bs-toggle="modal"
                    data-bs-target=".accept-buy"
                    @click="buy('personal', 'Дополнительная персонализация', 1, 100)"
                    class="btn btn-soft-success waves-effect waves-light"
                >
                  Приобрести
                </button>
              </div>
            </div>
          </div>
        </div>
        <!-- card body -->
      </div>
      <!-- end card -->

      <div class="card product m-0" style="max-width: 800px">
        <div class="card-body">
          <div class="row gy-3">
            <div class="col-sm-auto">
              <div class="avatar-lg bg-light rounded p-1">
                <img
                    src="@/assets/images/items/unban.png"
                    alt=""
                    class="img-fluid d-block"
                />
              </div>
            </div>
            <div class="col-sm">
              <h5 class="fs-14 text-truncate">
                <span class="text-dark">
                  Разбан
                </span>
              </h5>
              <ul class="list-inline text-muted">
                Позволяет снять блокировку с аккаунта<br>
              </ul>
            </div>
            <div class="col-sm-auto">
              <div class="text-lg-end">
                <p class="text-muted mb-1">Стоимость предмета:</p>
                <h5 class="fs-14">
                  ₽<span id="ticket_price" class="product-price">350</span>
                </h5>
                <button
                    data-bs-toggle="modal"
                    data-bs-target=".accept-buy"
                    @click="buy('unban', 'Разбан', 1, 350)"
                    class="btn btn-soft-success waves-effect waves-light"
                >
                  Приобрести
                </button>
              </div>
            </div>
          </div>
        </div>
        <!-- card body -->
      </div>
      <!-- end card -->
    </div>

    <div
        class="modal fade accept-buy"
        tabindex="-1"
        role="dialog"
        aria-labelledby="mySmallModalLabel"
        aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-body text-center p-5">
            <img src="@/assets/images/gifs/basket.gif">
            <div class="mt-4">
              <h4 class="mb-3">Вы уверены в покупке {{ product.name }}?</h4>
              <p v-if="product.count > 1" class="text-muted mb-0">
                  С вашего счета спишется <b>{{ product.price * product.count }}₽</b> за покупку
                  <b>{{ product.count }}</b> единиц товара.
              </p>
              <p v-else class="text-muted mb-0">
                С вашего счета спишется {{ product.price }}₽ за покупку
              </p>
              <p class="text-muted mb-4">
                <b>Вы должны находиться на сервере для выдачи!</b>
              </p>
              <div class="hstack gap-2 justify-content-center">
                <button
                    type="button"
                    class="btn btn-danger"
                    data-bs-dismiss="modal"
                >
                  Отмена
                </button>
                <a
                    @click="sendBuy"
                    class="btn btn-success"
                    data-bs-dismiss="modal"
                >
                  Купить
                </a>
              </div>
            </div>
          </div>
        </div>
        <!-- /.modal-content -->
      </div>
      <!-- /.modal-dialog -->
    </div>
    <!-- /.modal -->

  </Layout>
</template>

<style scoped>

</style>